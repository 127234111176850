import { Entity as EntityEnum } from '../../constants/Entity'
import type { Entity } from '../../types'

export const isSupportedEntity = (value?: string | null): value is Entity => {
  if (!value) {
    return false
  }

  return Object.values(EntityEnum).includes(value as Entity)
}
