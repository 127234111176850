const contentSecurityPolicy = `
  default-src 'self' https://*.leaseplancdn.com https://assets.onfido.com https://sdk.onfido.com;
  script-src 'self' 'unsafe-eval' 'report-sample' https://assets.onfido.com https://sdk.onfido.com https://*.sentry.io https://*.sardine.ai;
  style-src 'self' 'unsafe-inline' 'report-sample' https://*.leaseplancdn.com https://assets.onfido.com https://sdk.onfido.com;
  connect-src 'self' data: blob: *.onfido.com wss://*.onfido.com https://*.sentry.io https://*.browser-intake-datadoghq.eu;
  img-src 'self' data: blob: https://*.leaseplancdn.com https://assets.onfido.com https://sdk.onfido.com;
  media-src blob: https://assets.onfido.com https://sdk.onfido.com;
  worker-src 'self' blob:;
  object-src 'self' blob:;
  frame-src 'self' data: blob: https://*.sardine.ai https://sdk.onfido.com;
  child-src 'self' data: blob: https://*.sardine.ai https://sdk.onfido.com;
  frame-ancestors 'none';
`
const defaultSrc = "default-src 'self'"
const connectSrc = "connect-src 'self'"

const contentSecurityPolicyDev = contentSecurityPolicy
  .replace(defaultSrc, `${defaultSrc} localhost:*`)
  .replace(connectSrc, `${connectSrc} ws://localhost:3000`)

const csp = (
  process.env.NODE_ENV !== 'development'
    ? contentSecurityPolicy
    : contentSecurityPolicyDev
)
  .replace(/\s{2,}/g, ' ')
  .trim()

module.exports = csp
