import { encode } from 'querystring'

import type { Context } from '../../types'
import { isNextApiRequest } from '../check/isNextApiRequest'

const ALLOWED_QUERY_PARAMS = [
  'entity',
  'applicant_id',
  'lang',
  'mode',
  'recipient_id',
  'case_id',
]

const filterQueryParams = (params: URLSearchParams) => {
  const keys = Array.from(params.keys())
  for (const key of keys) {
    if (!ALLOWED_QUERY_PARAMS.includes(key.toLowerCase())) {
      params.delete(key)
      continue
    }

    const value = params.get(key)
    if (typeof value !== 'string' || value.length === 0) {
      params.delete(key)
      continue
    }

    if (key !== key.toLowerCase()) {
      params.delete(key)
      params.append(key.toLowerCase(), value)
    }
  }

  return params
}

export const getParams = (ctx: Context) => {
  let referrerParams = new URLSearchParams()

  if (isNextApiRequest(ctx)) {
    referrerParams = new URLSearchParams(
      ctx.headers.referer?.toLowerCase() ?? '',
    )
  }

  const queryParams = new URLSearchParams(encode(ctx.query))

  const combined = new URLSearchParams({
    ...Object.fromEntries(referrerParams),
    ...Object.fromEntries(queryParams),
  })

  return filterQueryParams(combined)
}
