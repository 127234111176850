/* eslint-disable @next/next/no-css-tags */
import '@datadog/browser-logs'
import AyvensFaviconReact from '@velocity/icons/favicon/ayvens-favicon-react'
import LeaseplanFaviconReact from '@velocity/icons/favicon/leaseplan-favicon-react'
import AyvensTheme from '@velocity/styling/themes/ayvens/theme'
import LeaseplanTheme from '@velocity/styling/themes/leaseplan/theme'
import { VelocityProvider } from '@velocity/ui'
import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useEffect } from 'react'
import { createGlobalStyle } from 'styled-components'
import { reset } from 'styled-reset'

import csp from '../../csp.config'
import { useAyvensThemeRequested } from '../hooks/useAyvensThemeRequested'
import type { AyvensThemePageProp } from '../types'
import { isServer } from '../utils/check'
import { datadogRum } from '../utils/log/datadogRum'

const cspMeta = csp.replace(/frame-ancestors[^;]*;/, '').replace(/\s{2,}/g, ' ')

const GlobalStyle = createGlobalStyle`
  ${reset}
  html,
  body,
  main, 
  #__next {
   height: 100%;
  }

  html,
  body {
    box-sizing: border-box;
    font-display: fallback;
    margin: 0;
    font-family: 'Arial', 'Helvetica', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f8fb;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
`

type Props = AppProps & {
  Component: NextPage<AyvensThemePageProp>
  pageProps: {}
}

const Application = ({ Component, pageProps }: Props) => {
  const useAyvensTheme = useAyvensThemeRequested()
  const name = useAyvensTheme ? 'Ayvens' : 'LeasePlan'

  useEffect(() => {
    if (!isServer()) {
      datadogRum.startSessionReplayRecording()
    }
  }, [])

  return (
    <VelocityProvider theme={useAyvensTheme ? AyvensTheme : LeaseplanTheme}>
      <GlobalStyle />
      <Head>
        <title>{name} verification app</title>
        {useAyvensTheme ? <AyvensFaviconReact /> : <LeaseplanFaviconReact />}
        <meta
          name="description"
          content={`Verify identity and address for ${name}.`}
        />
        <meta httpEquiv="Content-Security-Policy" content={cspMeta} />
        <link
          rel="stylesheet"
          href={`/_next/static/node_modules/@velocity/styling/themes/${name.toLowerCase()}/theme.css`}
        />
      </Head>
      <Component useAyvensTheme={useAyvensTheme} {...pageProps} />
    </VelocityProvider>
  )
}

export default Application
