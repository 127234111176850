export enum Entity {
  LPAT = 'LPAT',
  AVAT = 'AVAT',
  LPUK = 'LPUK',
  AVUK = 'AVUK',
  LPNL = 'LPNL',
  AVNL = 'AVNL',
  LPPT = 'LPPT',
  AVPT = 'AVPT',
  LPSK = 'LPSK',
  AVSK = 'AVSK',
  LPGR = 'LPGR',
  AVGR = 'AVGR',
}
