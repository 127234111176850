import { getParams } from './getParams'
import { Entity } from '../../constants'
import type { Context } from '../../types'
import { isSupportedEntity } from '../check'

export const DEFAULT_ENTITY: Entity = Entity.LPUK

const getEntityFromQueryParams = (ctx: Context) => {
  const entity = getParams(ctx).get('entity')

  if (entity && isSupportedEntity(entity.toUpperCase())) {
    return entity.toUpperCase() as Entity
  }

  return undefined
}

export const getEntity = (ctx: Context) =>
  getEntityFromQueryParams(ctx) ?? DEFAULT_ENTITY
