import type { Context } from '../../types'
import { getEntity } from '../params'

export const isAyvens = (ctx: Context) => {
  // try {
  const entity = getEntity(ctx)
  return entity.startsWith('AV')
  // } catch {
  //   return false
  // }
}
